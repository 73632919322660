import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Box } from '../../modules/common/components/box';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const ExiliumIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page exilium-page '} game="exilium">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Girls' Frontline 2: Exilium wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Girls' Frontline 2: Exilium. Check our
          guides, tier lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Play & Reroll on PC" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Play and reroll in Girls' Frontline 2: Exilium on PC!</h4>
          <OutboundLink href="https://bstk.me/o485XScik" target="_blank">
            <Button variant="primary">Reroll now on BlueStacks</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title="Active codes" />
      <p>
        Codes colored with <strong className="blue">this color</strong> give
        Collapse Piece so the currency used to do pulls.
      </p>
      <div className="codes servers">
        <Box isCentered>
          <p className="code">Darkwinter Servers</p>
          <ul>
            <li>
              <strong className="blue">DC100K</strong>
            </li>
            <li>
              <strong className="blue">ELMOLIVE</strong>
            </li>
            <li>
              <strong className="blue">OMNGKTWN</strong>
            </li>
            <li>GFL2LAUNCH</li>
            <li>GF2EXILIUM</li>
            <li>
              <strong className="blue">GFL2GIFT</strong>
            </li>
            <li>GFL2OTS14</li>
            <li>GFL2SUOMI</li>
            <li>GFL2REWARD</li>
            <li>
              <strong className="blue">1203GFL2</strong>
            </li>
            <li>GFL2GIFTME </li>
            <li>GFL2DOLLS </li>
            <li>GFL2A2C9LF</li>
          </ul>
        </Box>
        <Box isCentered>
          <p className="code">Haoplay Servers</p>
          <ul>
            <li>
              <strong className="blue">DC100K</strong>
            </li>
            <li>
              <strong className="blue">ELMOLIVE</strong>
            </li>
            <li>
              <strong className="blue">OMNGKTWN</strong>
            </li>
            <li>GFL2LAUNCH</li>
            <li>VEKWF3UB</li>
            <li>GWFPM4BU8Z9X</li>
            <li>EA3GVCM54</li>
            <li>SUOMI1205</li>
            <li>ULLRID1205</li>
            <li>GLASSISLE</li>
          </ul>
        </Box>
      </div>
      <p>
        How to redeem codes? Click the Settings button on the bottom left corner
        of the game's main interface, and click [Redeem Code] on the bottom
        right corner of the Personal Information page or use the{' '}
        <a
          href="https://gf2exilium.sunborngame.com/main/code"
          target="_blank"
          rel="noreferrer"
        >
          official website
        </a>
        .
      </p>
      <SectionHeader title="Guides" />
      <h5>Guides</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/gfl-exilium/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/gfl-exilium/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_beg.webp"
              alt="Beginner Guide"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/gfl-exilium/guides/reroll-guide"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
        <CategoryCard
          title="Combat Guide"
          link="/gfl-exilium/guides/combat-guide"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_combat.webp"
              alt="Combat Guide"
            />
          }
        />
        <CategoryCard
          title="Gacha explained"
          link="/gfl-exilium/guides/gacha-explained"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_gacha.webp"
              alt="Gacha explained"
            />
          }
        />
        <CategoryCard
          title="Affinity & Dispatch"
          link="/gfl-exilium/guides/affinity-and-dispatch"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_affinity.webp"
              alt="Affinity & Dispatch"
            />
          }
        />
        <CategoryCard
          title="Battle Pass"
          link="/gfl-exilium/guides/battle-pass"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_bp.webp"
              alt="Battle Pass"
            />
          }
        />
        <CategoryCard
          title="Characters & Progression"
          link="/gfl-exilium/guides/characters-and-progression"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_progression.webp"
              alt="Characters & Progression"
            />
          }
        />
        <CategoryCard
          title="Platoon System"
          link="/gfl-exilium/guides/platoon-guide"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_guild.webp"
              alt="Platoon Explained"
            />
          }
        />
        <CategoryCard
          title="Weapons & Attachments"
          link="/gfl-exilium/guides/weapons-and-attachments"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_attachment.webp"
              alt="Weapons & Attachments"
            />
          }
        />
        <CategoryCard
          title="Attachments Farming"
          link="/gfl-exilium/guides/attachment-farming"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_atta.webp"
              alt="Attachments Farming"
            />
          }
        />
      </Row>
      <h5>Database</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Characters"
          link="/gfl-exilium/characters"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_characters.jpg"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Weapons"
          link="/gfl-exilium/weapons"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_weapons.webp"
              alt="Weapons"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/gfl-exilium/tier-list"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_tier.jpg"
              alt="Weapons"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default ExiliumIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Girls' Frontline 2: Exilium Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Girls' Frontline 2: Exilium. Check our guides, tier lists and reviews for characters available in the game."
    game="exilium"
  />
);
